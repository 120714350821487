@import url('https://fonts.googleapis.com/css2?family=Advent+Pro:wght@100;700&family=Bebas+Neue&family=Cairo:wght@300;700&family=Edu+VIC+WA+NT+Beginner&family=Montserrat:wght@100&family=Mouse+Memoirs&family=Orbitron&family=Oswald:wght@200&family=Play&family=Rajdhani:wght@300;700&family=Teko:wght@300;400&family=Titillium+Web:ital,wght@0,700;1,200&display=swap');
*{
  font-family: 'Cairo', sans-serif;
  /* font-family: 'Edu VIC WA NT Beginner', cursive; */
  /* font-family: 'Play', sans-serif; */
  /* font-family: 'Titillium Web', sans-serif; */
  /* font-family: 'Rajdhani', sans-serif; */
  margin:0; padding:0;
  box-sizing: border-box;
  outline: none; border:none;
  transition: all .2s linear;
}
body{
  animation: fadeInLeft 1s linear;
}
header{
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border-bottom: 1px solid gainsboro;
}
.navbar--left{
  width: 30%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.navbar--logo{
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.navbar--logo--box{ 
  width: 90%;
  height: 90%;
}
.navbar--logo--box img{ 
  width: 100%;
  height: 100%;
}
.navbar--center{
  width: 40%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 1rem;
  border-bottom: 3px solid gainsboro;
  border-top: 3px solid gainsboro;
}
.navbar--right{
  width: 30%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.navbar--right--content{
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  margin-right:2rem;
}
.profil{
  width: 30%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0.5rem;
  cursor: pointer;
  border: 2px solid #031b4e;
  padding: 0.7rem;
  border-radius: 0.5rem;
}
.log{
  font-size: 1rem;
  margin-left: 0.3rem;
  color: #031b4e;
}
.logo{
  text-decoration: none;
  color: #000;
  font-weight: bold;
}
.login--part{
  width: 20%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.container--connexion{
  margin-left: auto;
    margin-right: auto;
    padding: 1rem;
    width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.container--first--part{
  width: 50%;
  height: 95vh;
  /* background-color: #0069ff; */
  background-color: #031b4e;
  border-radius: 0.5rem 0 0 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.containter--second--part{
  width: 50%;
  height: 95vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.container--login{
  width: 90%;
}
.login--content{
  width: 600px;
  padding:0 6rem;
}
.btn{
  width: 100%;
  margin-top: 1rem;
  margin-bottom: 1rem;
  /* background-color: #0069ff; */
  background-color: coral;
}
.btn:hover{
  background-color: #031b4e;
}
.login--content--header{
  color: #000;
}
.container--first--part--content--header{
  color: white;
  font-size: 3rem;
}
.main--header--part{
  /* border: 1px solid coral; */
  /* background-color: #0069ff; */
  background-color: #031b4e;
  text-align: center;
  padding: 2rem;
  font-weight: bold;
}
.category--link{
  color: #3490dc;
    text-decoration: none;
    background-color: transparent;
    color: #000;
}
.category--link:hover{
  color: #3490dc;
}
.question__link{
  color: #000;
  font-weight: 600;
}
.question__link:hover{
  text-decoration: none;
}
.question__description{
  color: gray;
}
.numbers--question--answers{
  color: #3490dc;
  font-weight: 600;
}
/* .question__title{
  display: flex;
  align-items: center;
  justify-content: space-between;
} */
.question__tags{
    font-weight: 700;
    background: #f3f5f9;
    border-radius: 3px;
    color: #031b4e;
    display: inline-block;
    margin: 4px 8px 4px 0;
    padding: 0.2rem 0.5rem;
    font-size: 0.7rem;
}
.btn--question{
  /* background-color: #0d6efd; */
  background-color: #031b4e;
    color: #fff;
    border-radius: 0.25rem;
    font-size: 1rem;
    font-weight: 700;
    text-decoration: none;
    padding: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 180px;
}
.link__question{
  text-decoration: none;
}
.main--part--question{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1rem;
}
.btn--back{
    border-radius: 0.25rem;
    color: #0069ff;
    font-size: 1rem;
    text-decoration: none;
    padding: 0.5rem;
    width: 130px;
    margin-right: 1rem;
    font-weight: bold;
}
.number__likes{
  font-size: 1.1rem;
  color: #0069ff;
  margin-bottom: 0 !important;
  margin: 0 0.5rem;
}
.user__name{
  font-size: 0.8rem;
  /* color: #0069ff; */
  margin-bottom: 0 !important;
  margin: 0 0.3rem;
  font-weight: bold;
}
.user__container img{
  width: 15px;
  height: 15px;
}
.comment {
  display: flex;
  justify-content: flex-start;
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding: 1rem;
}
.comment--author--name {
  color: #031b4e;
  font-size: 1rem;
  font-weight: bold;
  padding-left: 0.7rem;
}
.comment--author--info {
  display: flex;
  align-items: center;
  justify-content: center;
}
.comment--author {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.comment--ago{
  margin-left: 1rem;
  display: flex;
  align-items: center;
}
.comment--ago p{
  margin-bottom: 0 !important;
}
.answ{
  font-size: 0.7rem;
}
.question--detail--content {
  color: #3b455a;
  font-size: 1.02rem;
}
.question-detail__title {
  display: flex;
  align-items: flex-start;
  margin-bottom: 1.5rem;
}
.question-detail__head {
  text-transform: uppercase;
  color: #0069ff;
  font-size: 0.85rem;
  padding-left: 2.2rem;
}
.no-underline {
  text-decoration: none;
}

.comment__likes-count {
  padding-right: 1.3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.form-floating{
  margin-top: 1rem !important;
}
.container--login--header {
  display: flex;
  align-items: center;
  justify-content: center;
}
.btn--header{
  background-color: #0069ff;
  border-radius: 0.9rem;
  color: #f3f5f9;
  width: 200px;
  margin-right: 1rem;
  margin-bottom: 1rem;
  padding: 0.6rem;
  transition: ease-in-out .2s;
}
.question--detail--title{
  color: #031b4e;
    font-weight: bold;
    margin-left: 1rem;
}
pre{
  display: block;
    font-size: .875em;
    margin-bottom: 1rem;
    margin-top: 0;
    overflow: auto;
}
main{
  margin-top: 1rem;
}
.user--part{
  width: 320px;
  height: 130px;
  position: fixed;
  background-color: white;
  z-index: 1000;
  right: 30px;
  top: -300px;
  border-radius: 1rem;
  padding: 1rem;
  box-shadow: 0 0 10px black;
  transition: ease-in-out 1s;
}
.user--part > i{
  cursor: pointer;
}
.display--user--part{
  transition: ease-in-out 1s;
  top: 40px;
}
.btn--close--login{
  cursor: pointer;
  width: 10%;
  justify-content: end;
  display: flex;
}
.user--name{
  width: 90%;
}
.user--name--content{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content:flex-end;
}
.user--email{
  font-weight: bold;
  color: gray ;
}
.logout--btn{
  width: 100%;
  padding: 0.5rem;
  /* background-color: #0069ff; */
  /* background-color: #031b4e; */
  background-color: coral;
  color: white;
  border-radius: 0.5rem;
  cursor: pointer;
}

.mb-4 {
  margin-bottom: 1.5rem!important;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.buttonload {
  background-color: #04AA6D; /* Green background */
  border: none; /* Remove borders */
  color: white; /* White text */
  padding: 12px 24px; /* Some padding */
  font-size: 16px; /* Set a font-size */
}
.title{
  font-size: bold;
  font-style: italic;
}
.categories{
  /* text-align: center; */
  /* font-weight: bold; */
  margin-top: 1rem;
  margin-left: 1.4rem;
}
.footer{
  width: 100%;
  height: 50px;
  background-color: #031b4e;
  color: #f3f5f9;
  text-align: center;
  font-size: 1.1rem;
  font-weight: bold;
  /* margin-top: 1rem; */
}
.questions__likes{
  width: 100%;
  display: flex;
  align-items: center;
  font-weight: bold;
}
.teams--content--header{
  font-weight: bold;
}
.teams--content{
  width: 100%;
  padding:0.5rem;
  border: 1px solid gainsboro;
  border-radius: 0.7rem;
  margin-top: 1rem;
  text-align: center;
}
.btn--create--team{
  padding: 0.5rem;
  background-color: coral;
  width: 100%;
  border-radius: 0.7rem;
}
.main--part--newsletter{
  width: 100%;
  /* background-color: #556270; */
  /* background-color: #000; */
  /* padding: 3rem; */
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
  border-radius: 0.7rem;
}
.newsletter__grid{
  width: 100%;
  height: 100%;
  border: 1px solid gainsboro;
  border-radius: 0.7rem;
  text-align: center;
  padding: 1rem;
}
.btn--newsletter{
  padding: 0.7rem;
  background-color:coral;
  border-radius: 1rem;
}
.items{
  width: 100%;
}
.items img{
  width: 100%;
  height: 100%;
  margin-top: 1rem;
  border-radius: 1rem;
}
.main__part__social{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: 1rem;
  /* background-color: #3b455a; */
}
.social--media{
  font-size: 2rem;
  cursor: pointer;
  color: #0069ff;
}
.main--forum{
  width: 100%;
  display: flex;
  /* align-items: center; */
  justify-content: space-between;
  /* border: 1px solid coral; */
}
.main--forum--left{
  width: 20%;
  position: sticky;
  top: 0;
  height: 100%;
  z-index: 1000;
  padding: 0.5rem;
  margin-bottom: 1rem;
  /* border: 1px solid coral; */
}
.main--forum--right{
  width: 78%;
  /* border: 1px solid coral; */
}
.forum--content{
  width: 100%;
  padding: 0.5rem;
    /* border: 1px solid coral; */
}
.link--forum{
  text-decoration: none;
  color: #031b4e;
  font-weight: bold;
}
.user__container{
  width: 100%;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: end;
}
.question__answer__box{
  padding: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.question__answer__box > p{
 margin-bottom: 0;
 margin-left: 1rem;
}

@media only screen and (max-width:576px){
  .container--connexion{
    margin-left: 0;
      margin-right: 0;
      padding: 1rem;
      width: 100%;
    display: block;
  }
  .container--first--part{
    width: 50%;
    height: 95vh;
    /* background-color: #0069ff; */
    background-color: #031b4e;
    border-radius: 0.5rem 0 0 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .containter--second--part{
    width: 50%;
    height: 95vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .teams--content{
    display: none;
  }
  .main--part--newsletter{
    display: none;
  }
  header{
    height: 80px;
    background-color: gainsboro;
  }
  .navbar--logo{
    width: 80px;
    height: 80px;
  }
  .navbar--logo--box{ 
    width: 70%;
    height: 70%;
  }
  .navbar--right--content{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-right:0;
  }
  .profil{
    width: 85%;
    margin-right: 0;
    padding: 0.5rem;
    border-radius: 0.5rem;
  }
  .log{
    font-size: 0.75rem;
    margin-left: 0;
    color: #031b4e;
  }
  .container--first--part{
    width: 100%;
    height: 35vh;
    border-radius: 1rem;
  }
  .containter--second--part{
    width: 100%;
    height: 45vh;
    border-bottom: 3px solid gainsboro;
    border-radius: 1rem;
  }
  .container--login{
    height: 90%;
  }
  .login--content{
    width: 100%;
    padding-right: 0;
    padding-left: 0;
  }
  .user--part{
    right: 0;
    left: 0;
    margin: auto;
    top: -200px;
  }
  .display--user--part{
    transition: ease-in-out 1s;
    top: 40px;
  }
  .main--forum{
    width: 100%;
  }
  .main--forum--left{
    display: none;
  }
  .main--forum--right{
    width: 100%;
  }

}
@media only screen and (min-width:578px) and (max-width:766px) {
  .navbar--right--content{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-right:0;
  }
  .profil{
    width: 50%;
    margin-right: 0;
    padding: 0.5rem;
    border-radius: 0.5rem;
  }
  .log{
    font-size: 0.8rem;
  }

}